
import { defineComponent, PropType } from "vue";
import Img from "@/components/general/Img.vue";
import moment from "moment";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
type referredUser = {
  id: number;
  name: string;
  photo_url: string;
  email: string;
  phone: string;
  created_at: string;
  skills: object[];
};
export default defineComponent({
  name: "referred-users-data-table",
  components: {
    Img
  },
  props: {
    data: {
      type: Array as PropType<referredUser[]>,
      required: true
    }
  },
  setup(props) {
    const router = useRouter();
    const store = useStore();
    function formatTime(timeString: string) {
      return moment(timeString).format("ll");
    }

    function userSkills(userID: number) {
      if (props.data) return props.data.find(user => user.id == userID)?.skills;
    }

    function handleRedirectToUser(userId) {
      router.push(`/users/${userId}/personal`).then(() => {
        store.dispatch("UsersModule/getUserById", userId);
      });
    }
    return {
      handleRedirectToUser,
      formatTime,
      userSkills
    };
  }
});
