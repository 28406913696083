
import { computed, defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { useAbility } from "@casl/vue";
import Pagination from "@/components/pagination/Pagination.vue";
import LoadingSpinner from "@/components/general/LoadingSpinner.vue";
import ReferredUsersDataTable from "@/components/users/userProfile/fields/referred-users/referred-users-data-table.vue";

export default defineComponent({
  name: "referred-users-tab",
  components: {
    ReferredUsersDataTable,
    LoadingSpinner,
    Pagination
  },
  setup() {
    const loading = ref(false);
    const store = useStore();
    const route = useRoute();
    const referredUsers = computed(
      () => store.getters["UsersModule/getReferredUsers"]
    );
    const pagination = {
      currentPage: 1,
      lastPage: 1
    };
    function getUsers() {
      loading.value = true;
       store
        .dispatch("UsersModule/fetchReferredUsers", {
          id: route.params.id,
          page: pagination.currentPage
        })
        .then(pageCount => {
          pagination.lastPage = pageCount;
          loading.value = false;
        });
    }
    onMounted(() => {
      getUsers();
    });
    function handleChangePage(page: number) {
      pagination.currentPage = page;
      getUsers();
    }
    return {
      loading,
      pagination,
      handleChangePage,
      referredUsers
    };
  }
});
